import React from 'react';

// Define the types for Service and ServiceListItem
interface ServiceListItem {
  title: string;
  details: string[];
}

interface Service {
  title: string;
  description: string;
  servicesList: ServiceListItem[];
  packages?: {
    title: string;
    price: string;
    description: string;
  }[];
}

// Sample services data
const servicesData: Service[] = [
  {
    title: "NEET Guide UG",
    description: `At Map Your Career, we offer a wide range of services designed to guide NEET aspirants and students across various streams in their journey toward finding the perfect career and college. Our services are built to make the admission process simpler, clearer, and more accessible for every student. With over 1000+ students successfully guided into government and private medical colleges, we have a proven track record of helping students turn their dreams into reality.`,
    servicesList: [
      {
        title: "Admission Guidance",
        details: [
          "End-to-end guidance for the Admission Process.",
          "Explanatory YouTube videos for an easy admission process.",
          "Personalized guidance from dedicated advisors.",
          "College-wise detailed information including fee structure, location, ranking, facilities, and more!"
        ]
      },
      {
        title: "Admission Counselling",
        details: [
          "Marks, Fees, & Reservation Analysis-based Personal counselling.",
          "Get counselling sessions in every round for solutions to multiple queries.",
          "Be aware of all the Backup options in a variety of fields."
        ]
      },
      {
        title: "College Preferences",
        details: [
          "Full information and analysis of colleges, seat matrix, and previous cut-offs.",
          "Get a preference list tailored made for you."
        ]
      },
      {
        title: "College Cut-Offs",
        details: ["Get unlimited access to an extremely simplified cut-off."]
      },
      {
        title: "Admission Form Filling",
        details: [
          "Support with filling of application forms for All India Quota/ESI/AFMC/Deemed Universities & different state quota counselling.",
          "Checking and verification of your documents before starting the process."
        ]
      },
      {
        title: "Periodic Alerts",
        details: [
          "Get prompt messages and SMS on your phone so you never miss any deadline.",
          "Immediate updates on any changes in the admission process.",
          "Get instant updates on results, seat matrix, allotment lists, and notices.",
          "Constant reminders of the admission process deadlines."
        ]
      },
      {
        title: "Post-Allotment Assistance",
        details: [
          "Decision-making assistance on admission after allotment.",
          "Decision-making assistance on Fees, DD Details, and Hostel Facility after admission finalization."
        ]
      },
      {
        title: "Health Report",
        details: [
          "Get college health analysis (Hostel facility, no of beds, Years of establishment).",
          "Clear your doubts regarding connectivity issues."
        ]
      },
      {
        title: "Career Exploration",
        details: [
          "There are many more medical courses like BDS, BAMS, Physiotherapy, DNB, etc. Our Counsellors will open up a whole new world of opportunities for you."
        ]
      },
      {
        title: "Complete Tracking",
        details: [
          "At Map Your Career, we handhold you throughout the entire NEET UG Counselling process – from start to finish, till you get admission in a good medical college."
        ]
      }
    ]
  },
  {
    title: "NEET Guide PG",
    description: `For students pursuing post-graduate medical education, Map Your Career offers specialized NEET PG (Postgraduate) counselling. Our team helps NEET PG aspirants with seat allocation, college selection, and more.`,
    servicesList: [
      {
        title: "NEET PG Counselling",
        details: [
          "Understand the application process and exam structure for NEET PG.",
          "Evaluate options for specializations and medical institutions based on your goals.",
          "Assist with seat allocation and the counselling process for government and private institutions.",
          "Guide you through the document verification and counselling process."
        ]
      },
      {
        title: "Personalized Career Counselling",
        details: [
          "We understand that each student has unique aspirations. Our expert counsellors take the time to understand your individual interests, strengths, and goals, providing tailored advice that aligns with your academic and career path."
        ]
      },
      {
        title: "College Selection Assistance",
        details: [
          "Whether it’s choosing a medical college in India or abroad, we provide detailed insights into different institutions, ensuring that you select the best-fit college based on your preferences, budget, and career goals."
        ]
      },
      {
        title: "Admission Process Support",
        details: [
          "From preparing your documents to meeting submission deadlines, we guide you through the entire admission process, ensuring that you never miss important steps or dates."
        ]
      },
      {
        title: "Career Exploration",
        details: [
          "For many NEET aspirants, the path after securing admission can be confusing—whether to pursue MD, MS, DNB, CPS, or decide between various specialties and branches. With so many options, it’s easy to feel uncertain about which route is best suited for your career goals.",
          "We ensure that you understand the pros and cons of each path, helping you make an informed decision that aligns with your aspirations and long-term career objectives."
        ]
      },
      {
        title: "Complete Tracking",
        details: [
          "At Map Your Career, we handhold you throughout the entire NEET UG Counselling process – from start to finish, till you get admission in a good medical college."
        ]
      }
    ],
    packages: [
      {
        title: "Standard Service Package",
        price: "₹25,000 + GST",
        description: "This package includes all the essential services to guide you through the NEET UG or NEET PG counselling process."
      },
      {
        title: "Premium Service Package",
        price: "₹41,000 + GST",
        description: "For those seeking an enhanced level of support and more personalized attention, our Premium Service Package offers additional benefits."
      }
    ]
  }
];

// ServiceCard Component
const ServiceCard: React.FC<{ service: Service }> = ({ service }) => {
  return (
    <div className="service-card bg-gradient-to-r from-accent via-accent to-white to-white shadow-lg rounded-lg p-6 mb-8">
      <h2 className="text-3xl font-bold mb-12 text-secondary">{service.title}</h2>
      <p className="text-secondary mb-4">{service.description}</p>

      {/* Services List */}
      <div>
        <ul className="list-disc list-inside space-y-2 text-secondary">
          {service.servicesList.map((item, index) => (
            <li key={index}>
              <strong>{item.title}</strong>
              <ul className="list-inside pl-6">
                {item.details.map((detail, i) => (
                  <li key={i}>{detail}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>

      {/* Packages Section */}
      {service.packages && (
        <div className="mt-8">
          <h4 className="text-xl text-secondary font-semibold">Service Packages</h4>
          {service.packages.map((pkg, index) => (
            <div key={index} className="bg-indigo-100 p-4 my-4 rounded-lg">
              <h5 className="text-lg text-secondary font-semibold">{pkg.title}</h5>
              <p className="text-secondary">{pkg.price}</p>
              <p className="text-secondary">{pkg.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// Services Component
const Services: React.FC = () => {
  return (
    <div className="container mx-auto py-16 px-6">
      <h1 className="text-4xl font-bold text-center text-secondary mb-12">Our Services</h1>
      <div className="space-y-12">
        {servicesData.map((service, index) => (
          <ServiceCard key={index} service={service} />
        ))}
      </div>
    </div>
  );
};

export default Services;