import React from 'react';
import { ArrowRight, Award, Users, BookOpen, Phone, Medal, MonitorCheck, CheckCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[600px] flex items-center">
        <div className="absolute inset-0 z-0">
          <img
            src="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80"
            alt="Medical students"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-secondary bg-opacity-70"></div>
        </div>
        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-2xl text-white">
            <h1 className="text-5xl font-bold mb-6">Your Journey to Medical Excellence Starts Here</h1>
            <p className="text-xl mb-8">Expert guidance for NEET counselling to help you secure admission in top medical colleges.</p>
            <Link to="/contact" className="bg-accent text-secondary px-8 py-3 rounded-full font-bold inline-flex items-center hover:bg-opacity-90 transition-colors">
              Get Started <ArrowRight className="ml-2" />
            </Link>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-secondary">Why Choose Us</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            <div className="text-center p-6 bg-white rounded-lg shadow-lg">
              <Award className="w-12 h-12 mx-auto mb-4 text-secondary" />
              <h3 className="text-xl font-bold mb-2">Expert Guidance</h3>
              <p className="text-secondary">Over 10 years of experience in education and counseling, helping thousands of students secure admissions.</p>
            </div>
            <div className="text-center p-6 bg-white rounded-lg shadow-lg">
              <Users className="w-12 h-12 mx-auto mb-4 text-secondary" />
              <h3 className="text-xl font-bold mb-2">Personalized Counselling</h3>
              <p className="text-secondary">Tailored advice based on your academic background, career goals, and personal preferences.</p>
            </div>
            <div className="text-center p-6 bg-white rounded-lg shadow-lg">
              <BookOpen className="w-12 h-12 mx-auto mb-4 text-secondary" />
              <h3 className="text-xl font-bold mb-2">Top College Admissions</h3>
              <p className="text-secondary">We specialize in NEET counseling and admissions, ensuring students get into the best UG and PG programs.</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center p-6 bg-white rounded-lg shadow-lg">
              <Award className="w-12 h-12 mx-auto mb-4 text-secondary" />
              <h3 className="text-xl font-bold mb-2">End-to-End Support</h3>
              <p className="text-secondary">From choosing the right college to application assistance, interview prep, and documentation.</p>
            </div>
            <div className="text-center p-6 bg-white rounded-lg shadow-lg">
              <Users className="w-12 h-12 mx-auto mb-4 text-secondary" />
              <h3 className="text-xl font-bold mb-2">Data-Driven Approach</h3>
              <p className="text-secondary">We use the latest trends and success stories to guide students toward the best career path.</p>
            </div>
            <div className="text-center p-6 bg-white rounded-lg shadow-lg">
              <BookOpen className="w-12 h-12 mx-auto mb-4 text-secondary" />
              <h3 className="text-xl font-bold mb-2">Pan-India & Global Reach</h3>
              <p className="text-secondary">Whether you're looking for admissions in India or abroad, we’ve got you covered!</p>
            </div>
          </div>
        </div>
      </section>

      {/* Workflow Section */}
      <section className="py-16 bg-gradient-to-r from-accent via-accent to-white">
  <div className="container mx-auto px-4">
    <h2 className="text-4xl font-semibold text-center text-white mb-16">Our Course Workflow</h2>

    {/* Timeline Container */}
    <div className="relative">
      {/* Timeline Line */}
      <div className="absolute inset-0 flex justify-between items-center top-1/2 transform -translate-y-1/2">
        <div className="border-t-4 border-white w-full"></div>
      </div>

      {/* Timeline Steps */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-12">
        <div className="text-center p-6 bg-white rounded-full shadow-lg relative z-10 hover:scale-105 transition-all duration-300">
          <div className="mb-4">
            {/* Replacing IconComponent with React Icon */}
            <Phone className="w-12 h-12 mx-auto text-indigo-600" />
          </div>
          <h3 className="text-lg font-bold mb-2 text-secondary">One-on-One Counselling</h3>
          <p className="text-secondary text-base">Get personalized advice from our experts.</p>
        </div>

        <div className="text-center p-6 bg-white rounded-full shadow-lg relative z-10 hover:scale-105 transition-all duration-300">
          <div className="mb-4">
            {/* Replacing IconComponent with React Icon */}
            <Medal className="w-12 h-12 mx-auto text-indigo-600" />
          </div>
          <h3 className="text-lg font-bold mb-2 text-secondary">Success Roadmap</h3>
          <p className="text-secondary text-base">Receive a tailored plan for NEET success.</p>
        </div>

        <div className="text-center p-6 bg-white rounded-full shadow-lg relative z-10 hover:scale-105 transition-all duration-300">
          <div className="mb-4">
            {/* Replacing IconComponent with React Icon */}
            <MonitorCheck className="w-12 h-12 mx-auto text-indigo-600" />
          </div>
          <h3 className="text-lg font-bold mb-2 text-secondary">Tracking & Monitoring</h3>
          <p className="text-secondary text-base">We monitor your progress every step of the way.</p>
        </div>
      </div>
    </div>
  </div>
</section>
  



    {/* Process Section */}
    <div className="bg-gray-50 p-8 rounded-lg">
          <h2 className="text-3xl font-bold text-center text-secondary mb-8">Our Process</h2>
          <div className="space-y-4">
            {[
              "Confused about your career?",
              "Unsure which college to choose?",
              "Need guidance for NEET counseling?",
              "Looking for an advisor to help you through this crucial process?",
              "Want to learn about the top medical colleges?"
            ].map((step, index) => (
              <div key={index} className="flex items-center space-x-3 bg-white p-4 rounded-lg">
                <CheckCircle className="text-accent" />
                <span>{step}</span>
              </div>
            ))}
          </div>
        </div>


      {/* CTA Section */}
      <section className="py-16 bg-secondary text-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Start Your Medical Journey?</h2>
          <p className="text-xl mb-8">Book a free counselling session with our experts today</p>
          <Link to="/contact" className="bg-accent text-secondary px-8 py-3 rounded-full font-bold hover:bg-opacity-90 transition-colors">
            Schedule Consultation
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Home;