import React from 'react';
import { Award, Users, Target, Smile } from 'lucide-react';

const About = () => {
  return (
    <div className="py-8">
      <div className="container mx-auto px-4">
       {/* Hero Section */}
<div className="container mx-auto">
  <div className="flex flex-col md:flex-row items-center justify-center space-y-12 md:space-y-0 md:space-x-12 bg-gradient-to-r from-accent via-accent to-white rounded-3xl shadow-lg overflow-hidden mb-12">

    {/* Text Content */}
    <div className="relative md:w-2/3 px-6 py-12">
      <h1 className="text-4xl font-bold text-center text-secondary mb-12 text-indigo-800 mb-8 tracking-wide leading-tight transition-all transform hover:scale-105">
        About Us
      </h1>
      
      <div className="space-y-6 text-lg text-secondary">
        <div className="mb-6">
          At Map Your Career, our story begins with our own struggles—struggles that many students and their families face when it comes to navigating the complex world of education and career choices. As young professionals, we’ve been there too. We’ve experienced the confusion, the overwhelming documentation, and the lack of clear guidance in choosing the right career path.
        </div>
        <div className="mb-6">
          Like many aspiring students, we were caught in a web of complex procedures, unclear options, and the constant pressure to make the "right" decision. With no one to guide us, we often felt lost, unsure, and overwhelmed. What made it even harder was watching our peers, bright and talented students, compromise on their dreams simply because they didn’t have the right information or support to make informed choices.
        </div>
        <div className="mb-6">
          Driven by our personal experiences, we founded Map Your Career. Our mission is simple: to make the counselling process for aspirants—clear, straightforward, and supportive. We want to be the guide that we never had, helping students from all streams discover the best opportunities and reach their dreams without the confusion and frustration we once faced.
        </div>
        <div className="mb-6">
          Through Map Your Career, we’re committed to breaking down barriers, simplifying the process, and empowering students to make the right decisions for their future. Whether it's choosing the right medical college, understanding the admission process, or exploring global options, we’re here to help every step of the way.
        </div>
        <div className="mb-6">
          We believe that every student deserves a chance to follow their dreams without compromise. And with Map Your Career, we’re proud to be part of that journey.
        </div>
      </div>
    </div>

    {/* Image */}
    <div className="relative md:w-1/3 px-6 py-6">
      <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent opacity-20 rounded-lg"></div> {/* Background Gradient Overlay */}
      <img
        src="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80"
        alt="Map Your Career Team"
        className="w-full h-full object-cover rounded-xl shadow-xl transform transition-all hover:scale-110 hover:rotate-3"
      />
    </div>

  </div>
</div>



        {/* Mission & Vision */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <div className="p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-secondary mb-4">Our Mission</h2>
            <p className="text-secondary">
              At Map Your Career, our mission is to guide students through the challenging and often overwhelming process of choosing the right career and educational opportunities.
            </p>
          </div>
          <div className="p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-secondary mb-4">We aim to:</h2>
            <p className="text-secondary">
            <div>1.	<b>Simplify the Counselling Process:</b> We break down the complex admission procedures, making them easy to understand and navigate.</div>
            <div>2.	<b>Provide Personalized Guidance:</b> Offering tailored advice based on each student’s goals, needs, and preferences to help them make the best possible decisions.</div>
            <div>3.	<b>Raise Awareness:</b> We provide accurate and up-to-date information about medical colleges in India and abroad, ensuring students have access to the knowledge they need to make informed choices.</div>
            <div>4.	<b>Support Students at Every Step:</b> From application to admission, we are committed to being there for students, guiding them through each stage of the journey.</div>
            <div>5.	<b>Inspire Confidence:</b> We believe every student has the potential to achieve their dreams. Our mission is to empower students to pursue their goals without hesitation or fear.</div>

            </p>
          </div>
        </div>

        {/* Stats */}
        <section className="py-16 bg-gradient-to-r from-accent via-accent to-white">
  <div className="container mx-auto px-6 sm:px-4">
    <h2 className="text-4xl sm:text-3xl font-semibold text-white text-center mb-8 sm:mb-12">
      Our Core Ideology
    </h2>
    
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
      {[
        { icon: <Users className="w-12 h-12" />, number: "Transparency", label: "Honesty & Openness" },
        { icon: <Award className="w-12 h-12" />, number: "Quality", label: "Excellence in Service" },
        { icon: <Target className="w-12 h-12" />, number: "Commitment", label: "Dedicated Support" },
        { icon: <Smile className="w-12 h-12" />, number: "Excellence", label: "Continuous Improvement" }
      ].map((stat, index) => (
        <div key={index} className="text-center bg-white rounded-lg shadow-lg p-6 transform transition-all hover:scale-105 hover:shadow-xl hover:bg-indigo-50">
          <div className="mb-4 text-indigo-600">
            {stat.icon}
          </div>
          <div className="text-3xl sm:text-2xl font-bold text-secondary mb-2">{stat.number}</div>
          <div className="text-secondary">{stat.label}</div>
        </div>
      ))}
    </div>
  </div>
</section>



        {/* Team Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-center text-secondary mb-8">Our Expert Team</h2>
          <div className="grid md:grid-cols-3 gap-8">
          {[
            {
              image: "/images/Chetan.jpg",
              name: "CHETAN BHANDARI",
              role: "Director – Counsellor"
            },
            {
              image: "/images/Mahek.jpg",
              name: "MAHEK GADA",
              role: "Co-Founder"
            },
            {
              image: "/images/Aamir.jpg",
              name: "AAMIR",
              role: "Co-Founder"
            }
          ].map((member, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
              {/* Fixing width and height */}
              <img 
                src={member.image} 
                alt={member.name} 
                className="w-full h-58 object-cover" 
              />
              <div className="p-4">
                <h3 className="text-xl font-bold text-secondary">{member.name}</h3>
                <p className="text-secondary">{member.role}</p>
              </div>
            </div>
          ))}
        </div>

        </div>
      </div>
    </div>
  );
};

export default About;